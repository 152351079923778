import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const ProjectContext = createContext();

export const useMyContext = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
    const [currentProject, setCurrentProject] = useState({
        displayedProducts: [],
        messages: [],
        conversationId: "",
        id: null,
        key: null,
        sub: null,
        projectName: "New Chat",
        lastModelUsed: null,
        canModifyDisplayedProducts: true
    })

    const [loading, setLoading] = useState(false);
    const [currentSub, setCurrentSub] = useState(null);
    const [defaultModel, setDefaultModel] = useState("");
    const [initialized, setInitialized] = useState(false);
    const [prebuiltPrompts, setPrebuiltPrompts] = useState([]);
    const [promptToSend, setPromptToSend] = useState('');

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV !== "production") {
            console.log("currentProject: ", currentProject)
        }
    }, [currentProject])


    const getUser = useCallback(async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 0 });
            console.log("response data sub: ", response)
            setCurrentSub(response.data.sub);
            setPrebuiltPrompts(response.data.prebuilt_prompts);
            return {
                sub_param: response.data.sub
            };
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("error retrieving user: ", error)
            }
        }
    }, []);

    const fetchProjects = useCallback(async (sub_param) => {
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 1, sub: sub_param });
            //console.log("response from fetch: ", response)
            setDefaultModel(response.data.default_model);
            return response.data.default_model
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.error("error fetching projects", error)
            }
        }
    }, [])

    const fetchProjectDetails = async (projectId) => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, {
                call: 2,
                conversation_id: projectId,
                sub: currentSub
            });

            const conversationHistory = response.data.conversation_history;

            setCurrentProject({
                displayedProducts: response.data.displayed_products,
                messages: conversationHistory,
                conversationId: projectId,
                key: response.data.key,
                sub: response.data.sub,
                projectName: response.data.project_name,
                lastModelUsed: response.data.last_model_used,
                canModifyDisplayedProducts: currentProject.canModifyDisplayedProducts
            })

            setDefaultModel(response.data.last_model_used)
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("error fetching project details: ", error)
            }
        }
    }

    const loadNewProject = useCallback(async (sub_param, default_model) => {
        //console.log("loadNewProject called. default_model is: ", default_model)
        try {
            const newCurrentProject = {
                displayedProducts: [],
                messages: [],
                conversationId: uuidv4(),
                key: null,
                sub: sub_param,
                projectName: "New Chat!",
                lastModelUsed: default_model,
                canModifyDisplayedProducts: true
            };

            setCurrentProject(newCurrentProject);

        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error("error loading new project: ", error)
            }
        }
    }, []);

    useEffect(() => {
        const initialize = async () => {
            const { sub_param } = await getUser()

            const default_model = await fetchProjects(sub_param);

            await loadNewProject(sub_param, default_model)

            setInitialized(true);
        };

        if (!initialized) {
            initialize();
        }
    }, [initialized, fetchProjects, getUser, loadNewProject]);

    const saveProject = async (project, triggerLoader = false, cancelRun=false) => {
        if (triggerLoader) {
          setLoading(true);
        }
      
        try {
          await axios.post(process.env.REACT_APP_NO_STREAM_API, {
            call: 4,
            sub: project.sub,
            project_name: 'default project name',
            project,
            cancelRun
          });
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error saving project: ', error);
            }
        } finally {
          if (triggerLoader) {
            setLoading(false);
          }
        }
    };

    return (
        <ProjectContext.Provider value={{
            currentSub,
            currentProject,
            defaultModel,
            fetchProjects,
            saveProject,
            setCurrentProject,
            fetchProjectDetails,
            loading,
            prebuiltPrompts,
            promptToSend, 
            setPromptToSend
        }}>
            {children}
        </ProjectContext.Provider>

    );


};