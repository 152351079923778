import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ChatBar.css';
import { useMyContext } from '../../../../../context/ProjectProvider';
import { BlockMath } from 'react-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ChatBar = () => {
    const [inputValue, setInputValue] = useState('');
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isTimeoutActive, setIsTimeoutActive] = useState(false); // New state for tracking timeout
    const [uploadedImage] = useState(null);
    const [userScrolled, setUserScrolled] = useState(false);

    const inputRef = useRef(null);
    const chatMessagesRef = useRef(null);
    const abortControllerRef = useRef(null);
    const displayedMessagesRef = useRef([]);
    const userMessageRef = useRef(null);
    const [isMessagesCollapsed, setIsMessagesCollapsed] = useState(false); // Estado para colapsar mensajes

    const {
        currentSub,
        currentProject,
        setCurrentProject,
        fetchProjects,
        fetchProjectDetails,
        saveProject,
        promptToSend,
        setPromptToSend
    } = useMyContext();



    //function that handles each chunk

    const onMessageUpdate = useCallback((deltaData) => {
        //if the chunk indicates that it signals a new message
        if (deltaData.new_message) {
            if (!isMessagesCollapsed && deltaData.role ==="system-display_products") {
                setIsMessagesCollapsed(true);
            }
            setDisplayedMessages(prevDisplayedMessages => {
                const newDisplayedMessages = [
                    ...prevDisplayedMessages,
                    { role: deltaData.role, parts: ['']}
                ];
                displayedMessagesRef.current = newDisplayedMessages; //update the ref
                return newDisplayedMessages;
            });
        //if the chunk indicates that it contains a text delta or other type of delta
        } else if (deltaData.text_delta) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (prevDisplayedMessages.length === 0) {
                    newDisplayedMessages = [{ role: 'model', parts: [deltaData.text_delta] }];
                } else {
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1]
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [lastMessage.parts[0] + deltaData.text_delta]
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0, -1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages;
                return newDisplayedMessages;
            });
        } else if (deltaData.progress_update) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (
                    prevDisplayedMessages.length === 0 ||
                    prevDisplayedMessages[prevDisplayedMessages.length - 1].role !== 'system-progress_update'
                ) {
                    //start a new progress update message
                    newDisplayedMessages = [
                        ...prevDisplayedMessages,
                        { role: 'system-progress_update', parts: [deltaData.progress_update] }
                    ];
                } else {
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [deltaData.progress_update] // replace with the latest progress update
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0,-1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages;
                return newDisplayedMessages;
            });
        } else if (deltaData.notification) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                if (
                    lastMessage.role === 'system-notification' &&
                    lastMessage.parts[0] === ''
                ) {
                    // update the last messages parts with the notification text
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [deltaData.notification]
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0,-1), updatedMessage];
                } else {
                    //add a new message
                    newDisplayedMessages = [
                        ...prevDisplayedMessages,
                        { role: 'system-notification', parts: [deltaData.notification] }
                    ];
                }
                displayedMessagesRef.current = newDisplayedMessages;
                return newDisplayedMessages;
            });
        } else if (deltaData.calculator_name_delta || deltaData.calculator_description_delta || deltaData.calculator_arguments_delta) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (prevDisplayedMessages.length === 0) {
                    newDisplayedMessages = [{ role: 'model-calculator', parts: [deltaData]}];
                } else {
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    let updatedParts = lastMessage.parts[0]
                    if (typeof updatedParts ==='string') {
                        updatedParts = {};
                    }
                    if (deltaData.calculator_name_delta) {
                        updatedParts.calculator_name = (updatedParts.calculator_name || '') + deltaData.calculator_name_delta;
                    }
                    if (deltaData.calculator_description_delta) {
                        updatedParts.calculator_description = (updatedParts.calculator_description || '') + deltaData.calculator_description_delta;
                    }
                    if(deltaData.calculator_arguments_delta) {
                        updatedParts.calculator_arguments = (updatedParts.calculator_arguments || '') + deltaData.calculator_arguments_delta
                    }
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [updatedParts]
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0,-1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages;
                return newDisplayedMessages;
            });
        }
    },[isMessagesCollapsed]);

    const onStreamEnd = useCallback(async (sub_param, currentProject_param) => {
        setIsStreaming(false);
        setIsSendingMessage(false);

        const lastMessage = displayedMessagesRef.current[displayedMessagesRef.current.length - 1];

        // Check if the last message is a "system-notification" starting with "You have reached"
        if (lastMessage?.role === 'system-notification' && lastMessage.parts[0].startsWith('You have reached')) {

            setIsTimeoutActive(true); // Disable send button during timeout

            // Delay both fetching and clearing displayedMessages only for this specific case
            setTimeout(async () => {
                // Fetch latest projects and project details after delay
                
                await fetchProjects(sub_param);
                await fetchProjectDetails(currentProject_param.conversationId);

                // Clear displayedMessages after fetching
                setDisplayedMessages([]);
                displayedMessagesRef.current = []; // Clear the ref

                setIsTimeoutActive(false); // Re-enable send button after timeout
            }, 5000); // Delay by 5 seconds (adjust as needed)
        } else {
            // For all other cases, fetch and clear immediately
            await fetchProjects(sub_param);
            await fetchProjectDetails(currentProject_param.conversationId);

            setDisplayedMessages([]);
            displayedMessagesRef.current = []; // Reset the ref
        }
    }, [fetchProjects, fetchProjectDetails]);

    

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isSendingMessage) {
            e.preventDefault();
            handleSendMessage(undefined, handleCleanup, currentSub);
        }
    };

    const handleScroll = () => {
        const chatMessages = chatMessagesRef.current;
        if (chatMessages) {
            const isAtBottom =
                Math.abs(chatMessages.scrollHeight - chatMessages.clientHeight - chatMessages.scrollTop) < 1;
            setUserScrolled(!isAtBottom);
            console.log(userScrolled)
        }
    };

    const handleStopButtonClick = () => {
        if (isStreaming) {
            stopProcess();
        }
    };

    const stopProcess = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
            
            //cleanup is not handled here. its handled in the catchbock of send message
        }
    };

    const handleCleanup = useCallback(async (sub_param, currentProject_param) => {
        console.log("construido handleCleanUp")
        setIsStreaming(false);
        setIsSendingMessage(false);
    
        // Create an updatedProject that includes the user's message and the displayedMessages
        const updatedProject = {
            ...currentProject_param,
            messages: [
                ...currentProject_param.messages,
                userMessageRef.current, // Append user's message
                ...displayedMessagesRef.current // Then append displayed messages
            ],
        };
    
        // Clear displayedMessages and refs before updating the state
        setDisplayedMessages([]);
        displayedMessagesRef.current = [];
        userMessageRef.current = null;
    
        // Update currentProject in context
        setCurrentProject(updatedProject);
    
        // Save the updated project
        await saveProject(updatedProject, true, true);
    
        // Fetch latest projects and project details
        await fetchProjects(sub_param);
        await fetchProjectDetails(currentProject_param.conversationId);
    },[fetchProjects, fetchProjectDetails, saveProject, setCurrentProject]);

    const sendMessage = useCallback(async ({ currentSub, currentProject, onMessageUpdate, onStreamEnd, handleCleanup }) => {
        let requestBody;
        let headers = {};

        if (uploadedImage) {
            requestBody = new FormData();
            requestBody.append('file', uploadedImage);
            requestBody.append('projectData', JSON.stringify(currentProject));
            requestBody.append('load_testing', false)
        } else {
            headers['Content-Type'] = 'application/json';

            let newRequestBody = {}

            newRequestBody = {
                ...currentProject
            }

            requestBody = JSON.stringify(newRequestBody);

        }

        try {
            abortControllerRef.current = new AbortController();
            setIsStreaming(true);

            const response = await fetch(process.env.REACT_APP_STREAM_API, {
                method: 'POST',
                headers: headers,
                body: requestBody,
                signal: abortControllerRef.current.signal
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                buffer += chunk;

                // Split the buffer using the delimiter
                const parts = buffer.split('#1&');
                buffer = parts.pop(); // The last part may be incomplete

                for (const jsonString of parts) {
                    if (jsonString.trim() === '') continue;

                    try {
                        const parsedChunk = JSON.parse(jsonString);

                        //console.log("parsed chunk: ", parsedChunk)

                        // Existing processing logic
                        if (parsedChunk.new_message) {
                            onMessageUpdate({ new_message: true, role: parsedChunk.role });
                        }
                        if (parsedChunk.text_delta) {
                            onMessageUpdate({ text_delta: parsedChunk.text_delta });
                        }
                        if (parsedChunk.progress_update) {
                            onMessageUpdate({ progress_update: parsedChunk.progress_update });
                        }
                        if (parsedChunk.notification) {
                            onMessageUpdate({ notification: parsedChunk.notification });
                        }
                        if (parsedChunk.displayed_products_accumulated) {
                            setCurrentProject((prevProject) => ({
                                ...prevProject,
                                displayedProducts: parsedChunk.displayed_products_accumulated
                            }));
                        }

                    } catch (error) {
                        if (process.env.REACT_APP_NODE_ENV !== 'production') {
                            console.error('Error parsing JSON string: ', jsonString, error);
                        }
                    }

                }
            }
            onStreamEnd(currentSub, currentProject);

        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("there has been an error: ", error)
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Fetch error:', error);
                }
            }
            
            // Perform cleanup regardless of the error type
            await handleCleanup(currentSub, currentProject);
        }

    }, [setCurrentProject, uploadedImage]);

    const handleSendMessage = useCallback(async (messageText, handleCleanup) => {
        console.log("construido handleSendMessage")
        // if messageText is provided it sends provided; if not, it sends inputValue
        const message = (messageText !== undefined ? messageText : inputValue).trim();
        if (message && !isTimeoutActive && !isSendingMessage) {
            setIsSendingMessage(true);
            const newMessage = {
                role: 'user',
                parts: [message]
            };
    
            userMessageRef.current = newMessage; // store user's message
    
            setCurrentProject((prevProject) => {
                const updatedProject = {
                    ...prevProject,
                    messages: [...prevProject.messages, newMessage]
                };
    
                if (messageText === undefined) {
                    setInputValue('');
                }
                setDisplayedMessages([]);
    
                displayedMessagesRef.current = [];
    
                sendMessage({ currentSub, currentProject: updatedProject, onMessageUpdate, onStreamEnd, handleCleanup });
                setPromptToSend('')
    
                return updatedProject;
            });
        }
    }, [currentSub, inputValue, isTimeoutActive, isSendingMessage, setCurrentProject, sendMessage, onMessageUpdate, onStreamEnd, setPromptToSend]);

    useEffect(() => {
        //console.log("handlesendmessage for prompttosend")
        if (promptToSend !== '') {
            const timeoutId = setTimeout(() => handleSendMessage(promptToSend, handleCleanup), 0);
            return () => clearTimeout(timeoutId); // Cleanup to avoid memory leaks
        }
    }, [currentSub, promptToSend, handleSendMessage, handleCleanup])

    const getMessageClass = (role) => {
        switch (role) {
            case 'user':
                return 'user-message';
            case 'model':
                return 'model-message';
            case 'model-calculator':
                return 'model-calculator-message';
            case 'system-progress_update':
                return 'system-progress-message';
            case 'system-notification':
                return 'system-notification-message';
            default:
                return '';
        }
    };

    const renderMessage = (message, role) => {
        if (role === 'system-progress_update') {
            return (
                <div className="progress-update-message">
                    <span>{message}</span>
                </div>
            );
        } else if (role === 'system-notification') {
            return (
                <div className="system-notification-message">
                    <span>{message}</span>
                </div>
            );
        } else if (typeof message === 'string') {
            // Split by Python code, block math, and inline math
            const parts = message.split(/(\$\$.*?\$\$|```python.*?```)/s).filter(Boolean);

    
            return parts.map((part, index) => {
                if (part.startsWith('```') && part.match(/^```python/)) {
                    // Python code block
                    const code = part.replace(/^```python/, '').replace(/```$/, '').trim();
                    return (
                        <SyntaxHighlighter
                            key={index}
                            language="python"
                            style={vscDarkPlus}
                        >
                            {code}
                        </SyntaxHighlighter>
                    );
                } else if (part.startsWith('$$') && part.endsWith('$$')) {
                    // Block math with KaTeX
                    const mathText = part.slice(2, -2).trim();
                    return (
                        <BlockMath key={index}>{mathText}</BlockMath>
                    );
                } else {
                    // Render other content as Markdown
                    return (
                        <ReactMarkdown
                            key={index}
                            remarkPlugins={[remarkGfm]}
                            components={{
                                a: ({ node, children, ...props }) => (
                                    <a {...props} target="_blank" rel="noopener noreferrer">
                                        {children}
                                    </a>
                                )
                            }}
                        >
                            {part}
                        </ReactMarkdown>
                    );
                }
            });

        } else if (typeof message === 'object') {
            return renderCalculatorMessage(message);
        }

    };

    const renderCalculatorMessage = (parts) => {
        const { calculator_name, calculator_description, calculator_arguments, calculator_output } = parts;
    
        return (
            <div className="calculator-container">
                {calculator_name && (
                    <div className="calculator-field">
                        <h4>Calculator Name</h4>
                        <div>{calculator_name}</div>
                    </div>
                )}
                {calculator_description && (
                    <div className="calculator-field">
                        <h4>Description</h4>
                        <div>{calculator_description}</div>
                    </div>
                )}
                {calculator_arguments && (
                    <div className="calculator-field">
                        <h4>Input Arguments</h4>
                        <ul>
                            {Object.entries(calculator_arguments).map(([key, value], index) => (
                                <li key={index}>
                                    <strong>{key}:</strong> {value}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {calculator_output && (
                    <div className="calculator-field calculator-output">
                        <h4>Output</h4>
                        <div>{calculator_output}</div>
                    </div>
                )}
            </div>
        );
    };
    

    const handleCollapseToggle = () => {
        setIsMessagesCollapsed((prevState) => !prevState);
    };

    useEffect(() => {
        if (inputRef.current) {
          // Restablece la altura para recalcular
          inputRef.current.style.height = '20px'; // Altura inicial para una línea
          const newHeight = Math.min(inputRef.current.scrollHeight, 300); // Calcula la altura hasta un máximo de 300px
      
          // Solo ajusta si supera la altura inicial
          if (newHeight > 40) { // Altura mínima para dos líneas
            inputRef.current.style.height = `${newHeight}px`;
          }
        }
      }, [inputValue]);
      

    return (
        <div className={`chat-bar`}>
            <div
                className={`chat-messages ${
                    isMessagesCollapsed ? 'collapsed' : ''
                } ${displayedMessages.length === 0 && currentProject.messages.length === 0 ? 'empty' : ''}`}
                ref={chatMessagesRef}
                onScroll={handleScroll}
            >
                {currentProject.messages
                    .filter((message) =>
                        ['user', 'model', 'model-calculator', 'system-progress_update', 'system-notification'].includes(
                            message.role
                        )
                    )
                    .map((message, index) => (
                        <div key={index} className={`chat-message ${getMessageClass(message.role)}`}>
                            {renderMessage(message.parts[0], message.role)}
                        </div>
                    ))}
                {displayedMessages.map((message, index) => (
                    <div key={`displayed-${index}`} className={`chat-message ${getMessageClass(message.role)}`}>
                        {renderMessage(message.parts[0], message.role)}
                    </div>
                ))}
            </div>
            <div className="chat-input-container">
                <textarea
                    ref={inputRef}
                    className="chat-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Busca uin producto..."
                />
                {isStreaming && (
                    <button className="stop-button" onClick={handleStopButtonClick}>
                        <i className="bi bi-stop-fill"></i>
                    </button>
                )}
                <button
                    className={`send-button ${!inputValue.trim() || isSendingMessage ? 'disabled' : ''} ${
                        isSendingMessage ? 'sending' : ''
                    }`}
                    onClick={() => handleSendMessage(undefined, handleCleanup)}
                    disabled={!inputValue.trim() || isSendingMessage}
                >
                    {isSendingMessage ? (
                        <div className="spinner-border send-button-spinner" role="status" aria-hidden="true"></div>
                    ) : !inputValue.trim() ? (
                        <i className="bi bi-search"></i>
                    ) : (
                        <i className="bi bi-search"></i>
                    )}
                </button>
                {/* Conditionally show the extra button */}
                {!(displayedMessages.length === 0 && currentProject.messages.length === 0) && (
                    <button className="extra-button" onClick={handleCollapseToggle}>
                        <i className="bi bi-list-nested"></i>
                    </button>
                )}
            </div>

            <div className="ai-disclaimer">
                Artificial intelligence can make mistakes. Always verify important information.
            </div>
        </div>
    );
};

export default ChatBar;
