import React from 'react';
import Header from './Components/Header/Header';
import Body from './Components/Body/Body';
import Footer from '../../ReusableComponents/Footer/Footer'; // Importa el Footer
import './Home.css'; // Asegúrate de tener este archivo de estilos

const Home = () => {
  return (
    <div className="home-container">
      <Header />
      <Body />
      <Footer /> {/* Agrega el Footer aquí */}
    </div>
  );
};

export default Home;
