import React from 'react';
import { ReactComponent as Logo } from '../../Assets/logo.svg';
import './HeaderLeft.css';
import { useNavigate, useLocation } from 'react-router-dom';

function HeaderLeft() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="header-left-container">
      <div className="header-left-inner">
        <div
          className="header-left-content"
          style={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          <Logo className="header-left-logo" />
          <div className="header-left-title" translate="no">Deeplayered</div>
        </div>
        {/* Otros elementos si es necesario */}
      </div>
    </div>
      );
    }

export default HeaderLeft;
