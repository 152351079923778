import React from 'react';
import './DisplayedProducts.css';
import { useMyContext } from '../../../../../context/ProjectProvider';
import { useNavigate } from 'react-router-dom';

function DisplayedProducts() {
    const { currentSub, currentProject } = useMyContext();
    const navigate = useNavigate();

    const products = currentProject?.displayedProducts || [];



    if (products.length === 0) {
        return null;
    }

    const handleProductClick = (link) => {
        if (link) {
            navigate(link);
        } else {
            alert("No hay enlace disponible para este producto.");
        }
    };

    return (
        <div className="displayed-products-container">
            <h2>Productos para la Suscripción: {currentSub}</h2>
            <ul className="product-list">
                {products.map((product, index) => (
                    <li
                        key={index}
                        className="product-item"
                        onClick={() => handleProductClick(product.link)}
                        style={{ cursor: product.link ? 'pointer' : 'default' }}
                    >
                        <h3 className="product-title">{product.Name}</h3>
                        <div className="product-content">
                            <div className="product-image">
                                <img
                                    src={product.image || 'https://via.placeholder.com/150'}
                                    alt={product.Name}
                                />
                            </div>
                            <div className="product-details">
                                <p><strong>ID:</strong> {product.id}</p>
                                <p><strong>Descripción:</strong> {product.description}</p>
                                <p><strong>Precio:</strong> ${product.price}</p>
                                <p><strong>Cantidad:</strong> {product.quantity}</p>
                                <p><strong>Categoría:</strong> {product.category}</p>
                                <p><strong>Estado:</strong> {product.status}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DisplayedProducts;
