import React from 'react';
import './Header.css';
import { ReactComponent as Logo } from '../../../../Assets/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import ChatBar from './ChatBar/ChatBar';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHeaderLeftClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="header-container">
      <header className="homepage-header">
        <div
          className="header-left"
          style={{ cursor: 'pointer' }}
          onClick={handleHeaderLeftClick}
        >
          <Logo className="header-logo" />
            <div className="header-title" translate="no">Deeplayered</div>
          </div>
          <div className="header-chatbar">
          <ChatBar />
        </div>
      </header>
    </div>
  );
}

export default Header;
