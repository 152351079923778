import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="homepage-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about">About us</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Terms &amp; policies</h4>
          <ul>
            <li><Link to="/terms-of-use">Terms of use</Link></li>
            <li><Link to="/privacy-policy">Privacy policy</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact us</h4>
          <p>contact@deeplayered.ai</p>
          {/* Botón de LinkedIn */}
          <a
            href="https://www.linkedin.com/company/102903646"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2023 - {new Date().getFullYear()} Deeplayered LLC. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
