import React, { useState } from 'react';
import './Body.css';
import DisplayedProducts from './DisplayedProducts/DisplayedProducts';
import { useMyContext } from '../../../../context/ProjectProvider';

function Body() {
  const [selectedPrompt, setSelectedPrompt] = useState('');

  const { setPromptToSend, currentProject } = useMyContext();

  const handlePromptClick = (prompt) => {
    setSelectedPrompt(prompt);
    setPromptToSend(prompt);
  };

  return (
    <div className="body-container">
      {currentProject.displayedProducts.length===0 && <TextTutorial />}
      {currentProject.displayedProducts.length===0 && <PromptsEg onPromptClick={handlePromptClick} />}
      <DisplayedProducts prompt={selectedPrompt} />
      
    </div>
  );
}

// Definimos el componente PromptsEg
function PromptsEg({ onPromptClick }) {
  const { prebuiltPrompts } = useMyContext();

  return (
    <div className="prompts-eg-container">
      {prebuiltPrompts.map((prompt, index) => (
        <button key={index} className="prompt-button" onClick={() => onPromptClick(prompt)}>
          {prompt}
        </button>
      ))}
    </div>
  );
}

// Definimos el componente TextTutorial
function TextTutorial() {
  return (
    <div className="text-tutorial">
      <h2>Find the perfect equipment using a smart calculator</h2>
    </div>
  );
}

export default Body;
